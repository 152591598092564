import { useMutation, useQuery } from '@apollo/client';
import { jwtDecode } from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Form, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';
import { InputButton, InputPassword, StringField } from '../../components/controls';
import { ModalPopup, ModalPopupProps, ProviderFooter, ProviderHeader, ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useForm } from '../../hooks/useForm';
import { mutationResetPassword, queryValidateResetPasswordToken } from '../../services';
import { PAGE_LINKS, REGEX_PATTERNS } from '../../utilities/constants';

import './ResetPassword.scss';
import { Provider } from '../../types';

let hintText = `Password must be at least 8 characters long, contain at least one lower case letter, one upper case letter, one digit, and one special character.`;

const pageTitle = [{ key: 'Account Activation', content: 'Reset Password', active: true }];

const invalidResetPasswordLinkOrToken = 'Invalid reset password link or token.';
const invalidResetPasswordToken = 'Invalid Reset password token.';

type ActivationToken = {
  exp: number;
  iat: number;
  sub: number;
  user: UserDetails;
};

type UserDetails = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isActivationToken: boolean;
  isProvider: boolean;
  provider: Provider;
  providerGroup: string;
  useSalutation: boolean;
};

type ResetPasswordForm = {
  password: string;
  confirmPassword: string;
};

const invalidLink = {
  title: 'CONFIRM',
  body: invalidResetPasswordLinkOrToken,
  showPopup: false,
};

const ResetPassword = () => {
  const { resetPasswordToken } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, updateSubmitted] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [modalPopupProps, setModalPopupProps] = useState<ModalPopupProps>(invalidLink);
  const [userDetails, setUserDetails] = useState<UserDetails>({} as UserDetails);
  const { login } = useContext(GlobalContext);
  const navigate = useNavigate();

  const { onChange, formState } = useForm<ResetPasswordForm>({} as ResetPasswordForm);

  const { password, confirmPassword } = formState;

  const isFormValid = (): boolean => {
    let isValid = true;

    if (!password || !confirmPassword || !isPasswordValid || !isConfirmPasswordValid) {
      isValid = false;
    }

    if (password !== confirmPassword) {
      isValid = false;
    }
    return isValid;
  };

  const [resetPassword, { loading }] = useMutation(mutationResetPassword, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const errorDetails = graphQLErrors[0];

        if (errorDetails.message === invalidResetPasswordToken) {
          setModalPopupProps({
            title: 'ALERT',
            body: invalidResetPasswordLinkOrToken,
            showPopup: true,
            onClose: () => {
              setModalPopupProps({ ...modalPopupProps, showPopup: false });
              navigate(PAGE_LINKS.login);
            },
          });
        } else {
          setErrorMessage(graphQLErrors.map((error) => error.message).join(', '));
        }
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        }
      }
    },
    variables: {
      resetPasswordInput: {
        id: userDetails.id,
        password: confirmPassword,
        resetPasswordToken: resetPasswordToken,
      },
    },
  });

  const {
    data: validateTokenResponse,
    loading: validateTokenLoading,
    error: validateTokenError,
  } = useQuery(queryValidateResetPasswordToken, {
    variables: {
      resetPasswordToken: resetPasswordToken,
    },
    fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
  });

  useEffect(() => {
    if (!userDetails.email && validateTokenResponse?.resetPasswordValidateToken === 'Valid Token') {
      try {
        if (!resetPasswordToken) {
          setModalPopupProps({
            title: 'ALERT',
            body: invalidResetPasswordLinkOrToken,
            showPopup: true,
            onClose: () => {
              setModalPopupProps({ ...modalPopupProps, showPopup: false });
              navigate(PAGE_LINKS.login);
            },
          });
          return;
        }
        const decodedToken: ActivationToken = jwtDecode(resetPasswordToken || '');

        if (decodedToken.exp * 1000 < Date.now()) {
          setModalPopupProps({
            title: 'ALERT',
            body: 'Invalid reset password link: Reset password link has been expired.',
            showPopup: true,
            onClose: () => {
              setModalPopupProps({ ...modalPopupProps, showPopup: false });
              navigate(PAGE_LINKS.login);
            },
          });
          return;
        }

        const userDetailsFromToken = decodedToken.user as UserDetails;
        userDetailsFromToken.id = decodedToken.sub;

        setUserDetails(userDetailsFromToken);
      } catch (error) {
        setModalPopupProps({
          title: 'ALERT',
          body: invalidResetPasswordLinkOrToken,
          showPopup: true,
          onClose: () => {
            setModalPopupProps({ ...modalPopupProps, showPopup: false });
            navigate(PAGE_LINKS.login);
          },
        });
      }
    }
  }, [
    modalPopupProps,
    navigate,
    resetPasswordToken,
    userDetails.email,
    validateTokenLoading,
    validateTokenResponse?.resetPasswordValidateToken,
  ]);

  if (validateTokenError) {
    const { graphQLErrors } = validateTokenError;
    if (graphQLErrors && graphQLErrors.length > 0 && modalPopupProps.showPopup === false) {
      const errorDetails = graphQLErrors[0];

      if (errorDetails.message.includes('Found last login at')) {
        navigate(PAGE_LINKS.login, { replace: true });
      } else if (errorDetails.message === 'Invalid Activation token.') {
        setModalPopupProps({
          title: 'ALERT',
          body: 'Invalid reset password link or token.',
          showPopup: true,
          onClose: () => {
            setModalPopupProps({ ...modalPopupProps, showPopup: false });
            navigate(PAGE_LINKS.login, { replace: true });
          },
        });
      }
    } else {
      if (validateTokenError && Array.isArray(validateTokenError)) {
        setErrorMessage(validateTokenError.map((error) => error.message).join(', '));
      }
    }
  }

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    updateSubmitted(true);

    if (!isFormValid()) {
      return;
    }

    setErrorMessage('');
    resetPassword()
      .then((response) => {
        const {
          data: { resetPassword: resetUserPassword },
        } = response;
        login(resetUserPassword);
      })
      .catch((error) => {
        setErrorMessage(error.map((err: Error) => err.message).join(', '));
      });
  };

  const consolidatedErrorMessage = errorMessage;
  return (
    <>
      <Item as="div" className="Provider-Form-Page">
        <ProviderHeader showAppName />
        <ProviderSubHeader pageTitle={pageTitle} />
        <Container fluid>
          <Item as="div" className="content">
            {/* ERROR  */}
            {consolidatedErrorMessage && (
              <Header block className="error" image={errorIcon} color="red" content={consolidatedErrorMessage} />
            )}
            <Header as="h5" textAlign="center" className="topTitle">
              Enter and confirm your new password.
            </Header>
            <Form>
              {userDetails.isProvider === true && (
                <StringField label="Doctor " text={userDetails.useSalutation === true ? 'Yes' : 'No'} />
              )}
              <StringField label="First Name " text={userDetails.firstName} />
              <StringField label="Last Name " text={userDetails.lastName} />
              <StringField label="Email " text={userDetails.email} />
              {userDetails.isProvider === true && (
                <>
                  <StringField label="Provider " text={userDetails.provider.name} />
                  <StringField label="Provider Group " text={userDetails.providerGroup || userDetails.provider.name} />
                </>
              )}
              <InputPassword
                AddClass={submitted && !isPasswordValid ? 'error-field' : ''}
                name="password"
                label="CREATE PASSWORD"
                placeholder="Password"
                required
                hint={hintText}
                value={password}
                onChange={({ target: { name, value } }) => {
                  setErrorMessage('');
                  setPasswordValid(REGEX_PATTERNS.password.test(value));
                  onChange(name, value);
                }}
              />
              <InputPassword
                name="confirmPassword"
                label="CONFIRM PASSWORD"
                placeholder="Confirm Password"
                required
                value={confirmPassword}
                onChange={({ target: { name, value } }) => {
                  setErrorMessage('');
                  setConfirmPasswordValid(REGEX_PATTERNS.password.test(value));
                  onChange(name, value);
                }}
                error={submitted && password !== confirmPassword && 'Passwords must match'}
              />

              <InputButton
                loading={loading}
                text="Continue"
                fluid
                requiredHintText
                addCssClasses="mb-0 empty-label"
                onClick={handleFormSubmit}
                disabled={!password && !confirmPassword}
              />
            </Form>
          </Item>
        </Container>
        <ProviderFooter />
      </Item>
      {modalPopupProps.showPopup && <ModalPopup {...modalPopupProps} size="tiny" />}
    </>
  );
};

export default ResetPassword;
