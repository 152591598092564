import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DateRangePicker, InputButton } from '../../../components/controls';
import { Loading } from '../../../components/shared';
import { useDownloadFile } from '../../../hooks';
import {
  queryDownloadCSVOrderingProviderContactDetails,
  queryDownloadCSVPatientsMissingInformationContacting,
  queryDownloadCSVPatientsMissingInformationHistoricalContacting,
  queryDownloadCSVPatientsMissingInformationNonContacting,
} from '../../../services';
import { DateRange } from '../../../types';

import { APP_DEFAULT_DATE_FORMAT } from '../../../utilities/constants';
import './Reporting.scss';

const REPORTS = {
  PATIENT_MISSING_INFO_ACTIVE_CONTACTING: {
    name: 'pmi_contacting_all_active',
    label: 'Current Patients in Missing Information System for Contacting',
    includeDateRangePicker: false,
    order: 1,
  },
  PATIENT_MISSING_INFO_ACTIVE_CONTACTING_HISTORICAL: {
    name: 'pmi_contacting_historical',
    label: 'Historical Patients in Missing Information System for Contacting',
    includeDateRangePicker: true,
    order: 2,
  },
  PATIENT_MISSING_INFO_ACTIVE_NON_CONTACTING: {
    name: 'pmi_non_contacting_all_active',
    label: 'Current Patients in Missing Information System for Generating PDFs',
    includeDateRangePicker: false,
    order: 3,
  },
  ORDERING_PROVIDER_PREFERRED_CONTACT_METHOD: {
    name: 'ordering_provider_preferred_contact_method',
    label: 'Ordering Provider Preferred Contact Method',
    includeDateRangePicker: false,
    order: 4,
  },
};

const Reporting = () => {
  const toastId = React.useRef<string | number | null>(null);

  const downloadFile = useDownloadFile();

  const showToaster = (message: string, color = false) => {
    if (toastId.current !== null) {
      toast.dismiss(toastId.current);
    }

    let toasterOptions: any = { autoClose: 5000 };

    if (color) {
      toasterOptions = { ...toasterOptions, theme: 'colored' };
    }

    toastId.current = toast.error(message, toasterOptions);
  };

  const [downloadCSVPMIContacting, { error: errorPMIContacting, loading: loadingPMIContacting }] = useLazyQuery(
    queryDownloadCSVPatientsMissingInformationContacting,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [downloadCSVPMIHistoricalContacting, { error: errorPMIHistoricalContacting, loading: loadingPMIHistoricalContacting }] =
    useLazyQuery(queryDownloadCSVPatientsMissingInformationHistoricalContacting, {
      fetchPolicy: 'no-cache',
    });

  const [downloadCSVPMINonContacting, { error: errorPMINonContacting, loading: loadingPMINonContacting }] = useLazyQuery(
    queryDownloadCSVPatientsMissingInformationNonContacting,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [
    downloadCSVOrderingProviderContactDetails,
    { error: errorOrderingProviderContactDetails, loading: loadingOrderingProviderContactDetails },
  ] = useLazyQuery(queryDownloadCSVOrderingProviderContactDetails, {
    fetchPolicy: 'no-cache',
  });

  const [dateRanges, setDateRanges] = useState<Record<string, DateRange>>({});

  const handleDownload = (reportKey: string) => {
    const { label, name } = REPORTS[reportKey as keyof typeof REPORTS];
    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

    switch (name) {
      case REPORTS.PATIENT_MISSING_INFO_ACTIVE_CONTACTING.name:
        downloadCSVPMIContacting({
          variables: {
            timezoneId,
          },
        }).then(({ data }) => {
          downloadFile(data.generateCSVPatientsMissingInformationContacting, reportKey, 'csv');
        });
        break;
      case REPORTS.PATIENT_MISSING_INFO_ACTIVE_NON_CONTACTING.name:
        downloadCSVPMINonContacting({
          variables: {
            timezoneId,
          },
        }).then(({ data }) => {
          downloadFile(data.generateCSVPatientsMissingInformationNonContacting, name, 'csv');
        });
        break;
      case REPORTS.PATIENT_MISSING_INFO_ACTIVE_CONTACTING_HISTORICAL.name:
        const { startDate, endDate } = dateRanges[name];

        if (!startDate || !endDate) {
          showToaster('Please select valid date range.');
          return;
        }

        downloadCSVPMIHistoricalContacting({
          variables: {
            timezoneId,
            startDate: moment(startDate).format(APP_DEFAULT_DATE_FORMAT),
            endDate: moment(endDate).format(APP_DEFAULT_DATE_FORMAT),
          },
        }).then(({ data }) => {
          downloadFile(data.generateCSVPatientsMissingInformationHistoricalContacting, label, 'csv');
        });
        break;
      case REPORTS.ORDERING_PROVIDER_PREFERRED_CONTACT_METHOD.name:
        downloadCSVOrderingProviderContactDetails().then(({ data }) => {
          downloadFile(data.generateCSVOrderingProviderContactDetails, name, 'csv');
        });
        break;
    }
  };

  const handleDateChange = (name: string, range: DateRange) => {
    setDateRanges((prevState) => ({
      ...prevState,
      [name]: range,
    }));
  };

  useEffect(() => {
    const initialRanges: Record<string, DateRange> = {};
    Object.keys(REPORTS).forEach((reportKey) => {
      const { name, includeDateRangePicker } = REPORTS[reportKey as keyof typeof REPORTS];

      if (includeDateRangePicker) {
        initialRanges[name] = {
          startDate: moment().subtract(31, 'days').toDate(),
          endDate: moment().subtract(1, 'days').toDate(),
        };
      }
    });
    setDateRanges(initialRanges);
  }, []);

  if (errorPMIContacting || errorPMINonContacting || errorPMIHistoricalContacting || errorOrderingProviderContactDetails) {
    showToaster('Error occurred while downloading the csv.  Please try again.  If issue persists, please reach support team.');
  }

  return (
    <div className="reporting-container">
      {(loadingPMIContacting ||
        loadingPMINonContacting ||
        loadingPMIHistoricalContacting ||
        loadingOrderingProviderContactDetails) && <Loading show />}
      {Object.keys(REPORTS).map((reportKey) => {
        const { label, includeDateRangePicker, name } = REPORTS[reportKey as keyof typeof REPORTS];
        return (
          <div key={name} className="report">
            <label>{label}</label>
            {includeDateRangePicker === true && dateRanges[name] && (
              <DateRangePicker
                key={name}
                name={name}
                startDate={dateRanges[name]?.startDate || undefined}
                endDate={dateRanges[name]?.endDate || undefined}
                onDateChange={(range) => handleDateChange(name, range)}
              />
            )}
            <InputButton text="Download CSV" inline={false} addCssClasses="" onClick={() => handleDownload(reportKey)} />
          </div>
        );
      })}
    </div>
  );
};

export default Reporting;
