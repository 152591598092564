import { useMutation, useQuery } from '@apollo/client';
import { jwtDecode } from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Form, Header, Item } from 'semantic-ui-react';
import errorIcon from '../../assets/img/error-icon.svg';
import { InputButton, InputPassword, InputTermPolicy, StringField } from '../../components/controls';
import { ModalPopup, ModalPopupProps, ProviderFooter, ProviderHeader, ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { useForm } from '../../hooks/useForm';
import { mutationActivateAccount } from '../../services';
import validateAccountActivationToken from '../../services/queries/validateAccountActivationToken';
import { PAGE_LINKS, REGEX_PATTERNS } from '../../utilities/constants';
import './AccountActivation.scss';
import { Provider } from '../../types';

let hintText = `Password must be at least 8 characters long, contain at least one lower case letter, one upper case letter, one digit, and one special character.`;

const pageTitle = [{ key: 'Account Activation', content: 'Account Activation', active: true }];

type ActivationToken = {
  exp: number;
  iat: number;
  sub: number;
  user: UserAccountActivation;
};

type UserAccountActivation = {
  id: number;
  brightreeLoginId: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isActivationToken: boolean;
  isProvider: boolean;
  provider: Provider;
  providerGroup: string;
  useSalutation: boolean;
};

type AccountActivationForm = {
  password: string;
  confirmPassword: string;
};

const invalidActivationLink = {
  title: 'CONFIRM',
  body: 'Invalid account activation link or token.',
  showPopup: false,
};

const AccountActivation = () => {
  const { login } = useContext(GlobalContext);
  const { activationToken } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, updateSubmitted] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [isTermsConditionsAccepted, setIsTermsConditionsAccepted] = useState(false);

  const [modalPopupProps, setModalPopupProps] = useState<ModalPopupProps>(invalidActivationLink);

  const [userDetails, setUserDetails] = useState<UserAccountActivation>({} as UserAccountActivation);

  const navigate = useNavigate();

  const { onChange, formState } = useForm<AccountActivationForm>({} as AccountActivationForm);
  const { password, confirmPassword } = formState;

  const [activateAccount, { loading }] = useMutation(mutationActivateAccount, {
    onError(errors) {
      const { graphQLErrors } = errors;
      if (graphQLErrors && graphQLErrors.length > 0) {
        const errorDetails = graphQLErrors[0];

        if (errorDetails.message === 'Invalid Activation token.') {
          setModalPopupProps({
            title: 'ALERT',
            body: 'Invalid account activation link or token.',
            showPopup: true,
            onClose: () => {
              setModalPopupProps({ ...modalPopupProps, showPopup: false });
              navigate(PAGE_LINKS.login);
            },
          });
        } else {
          setErrorMessage(graphQLErrors.map((error) => error.message).join(', '));
        }
      } else {
        if (errors && Array.isArray(errors)) {
          setErrorMessage(errors.map((error) => error.message).join(', '));
        }
      }
    },
    variables: {
      activateAccountInput: {
        id: +userDetails.id,
        password: confirmPassword,
        activationToken: activationToken,
      },
    },
  });

  const isFormValid = (): boolean => {
    let isValid = true;

    if (!password || !confirmPassword || !isPasswordValid || !isConfirmPasswordValid || !isTermsConditionsAccepted) {
      isValid = false;
    }

    if (password !== confirmPassword) {
      isValid = false;
    }
    return isValid;
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    updateSubmitted(true);

    if (!isFormValid()) {
      return;
    }

    setErrorMessage('');
    activateAccount()
      .then((response) => {
        const {
          data: { activateUser },
        } = response;
        login(activateUser);
      })
      .catch((error) => {
        setErrorMessage(error.map((err: Error) => err.message).join(', '));
      });
  };

  const {
    data: validateTokenResponse,
    loading: validateTokenLoading,
    error: validateTokenError,
  } = useQuery(validateAccountActivationToken, {
    variables: {
      activationToken: activationToken,
    },
    fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
  });

  useEffect(() => {
    if (!userDetails.email && validateTokenResponse?.validateToken === 'Valid Token') {
      try {
        if (!activationToken) {
          setModalPopupProps({
            title: 'ALERT',
            body: 'Invalid account activation link or token.',
            showPopup: true,
            onClose: () => {
              setModalPopupProps({ ...modalPopupProps, showPopup: false });
              navigate(PAGE_LINKS.login);
            },
          });
          return;
        }
        const decodedToken: ActivationToken = jwtDecode(activationToken || '');

        if (decodedToken.exp * 1000 < Date.now()) {
          setModalPopupProps({
            title: 'ALERT',
            body: 'Invalid activation link: Activation link has been expired.',
            showPopup: true,
            onClose: () => {
              setModalPopupProps({ ...modalPopupProps, showPopup: false });
              navigate(PAGE_LINKS.login);
            },
          });
          return;
        }

        const userDetailsFromToken = decodedToken.user as UserAccountActivation;
        userDetailsFromToken.id = decodedToken.sub;

        setUserDetails(userDetailsFromToken);
      } catch (error) {
        setModalPopupProps({
          title: 'ALERT',
          body: 'Invalid account activation link or token.',
          showPopup: true,
          onClose: () => {
            setModalPopupProps({ ...modalPopupProps, showPopup: false });
            navigate(PAGE_LINKS.login);
          },
        });
      }
    }
  }, [activationToken, modalPopupProps, navigate, userDetails.email, validateTokenLoading, validateTokenResponse?.validateToken]);

  if (validateTokenError) {
    const { graphQLErrors } = validateTokenError;
    if (graphQLErrors && graphQLErrors.length > 0 && modalPopupProps.showPopup === false) {
      const errorDetails = graphQLErrors[0];

      if (errorDetails.message === 'Invalid Activation token.') {
        setModalPopupProps({
          title: 'ALERT',
          body: 'Invalid account activation link or token.',
          showPopup: true,
          onClose: () => {
            setModalPopupProps({ ...modalPopupProps, showPopup: false });
            navigate(PAGE_LINKS.login);
          },
        });
      } else if (errorDetails.message === 'Account is already active.') {
        navigate('/', { replace: true });
      }
    } else {
      if (validateTokenError && Array.isArray(validateTokenError)) {
        setErrorMessage(validateTokenError.map((error) => error.message).join(', '));
      }
    }
  }

  const consolidatedErrorMessage = errorMessage;

  return (
    <>
      <Item as="div" className="Provider-Form-Page account-activation">
        <ProviderHeader />
        <ProviderSubHeader pageTitle={pageTitle} />
        <Container fluid>
          <Item as="div" className="content">
            {/* ERROR  */}
            {consolidatedErrorMessage && (
              <Header block className="error" image={errorIcon} color="red" content={consolidatedErrorMessage} />
            )}
            <Header as="h5" textAlign="center">
              Welcome to the BetterNight Provider Portal, please set your password and agree to the terms of use.
            </Header>
            <Form>
              {userDetails.isProvider === true && (
                <StringField label="Doctor " text={userDetails.useSalutation === true ? 'Yes' : 'No'} />
              )}
              <StringField label="First Name " text={userDetails.firstName} />
              <StringField label="Last Name " text={userDetails.lastName} />
              <StringField label="Email " text={userDetails.email} />
              {userDetails.isProvider === false && userDetails.brightreeLoginId && (
                <StringField label="Brightree User Key" text={userDetails.brightreeLoginId?.toString()} />
              )}
              {userDetails.isProvider === true && (
                <>
                  <StringField label="Provider " text={userDetails.provider?.name} />
                  <StringField label="Provider Group " text={userDetails.providerGroup || userDetails.provider?.name} />
                </>
              )}
              <InputPassword
                AddClass={submitted && !isPasswordValid ? 'error-field' : ''}
                name="password"
                label="CREATE PASSWORD"
                placeholder="Password"
                required
                hint={hintText}
                value={password}
                onChange={({ target: { name, value } }) => {
                  setErrorMessage('');
                  setPasswordValid(REGEX_PATTERNS.password.test(value));
                  onChange(name, value);
                }}
              />
              <InputPassword
                name="confirmPassword"
                label="CONFIRM PASSWORD"
                placeholder="Confirm Password"
                required
                value={confirmPassword}
                onChange={({ target: { name, value } }) => {
                  setErrorMessage('');
                  setConfirmPasswordValid(REGEX_PATTERNS.password.test(value));
                  onChange(name, value);
                }}
                error={submitted && password !== confirmPassword && 'Passwords must match'}
              />
              <InputTermPolicy
                inline
                error={submitted && !isTermsConditionsAccepted && 'Must be selected.'}
                addClasses={`empty-label${submitted && !isTermsConditionsAccepted ? 'error-field' : ''} `}
                onChange={(isChecked) => setIsTermsConditionsAccepted(isChecked)}
              />
              <InputButton
                loading={loading}
                text="Continue"
                fluid
                requiredHintText
                addCssClasses="mb-0 empty-label"
                onClick={handleFormSubmit}
                disabled={!password && !confirmPassword && !isTermsConditionsAccepted}
              />
            </Form>
          </Item>
        </Container>
        <ProviderFooter />
      </Item>
      {modalPopupProps.showPopup && <ModalPopup {...modalPopupProps} size="tiny" />}
    </>
  );
};

export default AccountActivation;
