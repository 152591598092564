import moment from 'moment';
import { useCallback } from 'react';

export const useDownloadFile = () => {
  const downloadFile = useCallback((data: any, fileName: string, fileExtension: 'pdf' | 'csv') => {
    let fileUrl = '';

    switch (fileExtension) {
      case 'csv':
        const file = new Blob([data], {
          type: 'text/csv',
        });

        fileUrl = URL.createObjectURL(file);
        break;

      case 'pdf':
        fileUrl = `data:application/pdf;base64,${data}`;
        break;
    }

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `Provider_Portal_${fileName.replaceAll(' ', '_')}_${moment(new Date()).format(
      'YYYY-MM-DD_HH:MM',
    )}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return downloadFile;
};
