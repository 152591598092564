import { jwtDecode } from 'jwt-decode';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { COUNTDOWN_SECONDS, GENERAL_CONSTANTS } from '../utilities/constants';
import { GlobalContext } from '../context/GlobalContext';

const useCountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const { maxSessionTime, isSessionAboutToExpire, setSessionAboutToExpire, logout } = useContext(GlobalContext);
  const token = sessionStorage.getItem(GENERAL_CONSTANTS.authToken);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleCountdown = useCallback(() => {
    const decodedToken: any = jwtDecode(token || '');
    const expiry = new Date(decodedToken.exp * 1000 - 5000).getTime();

    const maxDistance = new Date(new Date().getTime() + maxSessionTime * 1000).getTime();

    const now = new Date().getTime();
    const distance = Math.min(expiry - now, maxDistance);
    setTimeLeft(distance);

    const diff = Math.floor(distance / 1000);

    if (diff <= COUNTDOWN_SECONDS && !isSessionAboutToExpire) {
      setSessionAboutToExpire(true);
    }

    if (diff <= 0) {
      logout();
      if (timerRef.current) {
        clearInterval(timerRef.current); // Clear the timer if token is missing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionAboutToExpire, maxSessionTime, setSessionAboutToExpire, token]);

  useEffect(() => {
    if (!token) {
      setTimeLeft(0);
      return;
    }

    handleCountdown();
    timerRef.current = setInterval(handleCountdown, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // Clear the timer if token is missing
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionAboutToExpire, maxSessionTime, setSessionAboutToExpire, token]);

  const formatTime = (time: number) => {
    const seconds = Math.abs(Math.floor((time / 1000) % 60));
    const minutes = Math.floor((time / 1000 / 60) % 60);

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return formatTime(timeLeft);
};

export default useCountdownTimer;
