import { upperFirst, lowerCase } from 'lodash';
import { useState } from 'react';
import { REGEX_PATTERNS } from '../utilities/constants';

export function useForm<T>(initialState: T) {
  const [formState, setFormState] = useState<T>(initialState);

  const onChange = <CT>(name: string, value: CT) => {
    setFormState({ ...formState, [name]: value });
  };

  const renderEmailError = (fieldValue: string) => {
    if (fieldValue.length > 100) {
      return 'Email may be a maximum of 100 characters.';
    }

    if (!REGEX_PATTERNS.email.test(fieldValue)) {
      return 'A valid email address is required';
    }

    return '';
  };

  const renderTextFieldError = (fieldName: string, fieldValue: string) => {
    if (!REGEX_PATTERNS.name.test(fieldValue)) {
      return `${fieldName} can only contain letters, apostrophes, hyphens, and periods.`;
    }

    if (fieldValue.length > 50) {
      return `${fieldName} may be a maximum of 50 characters.`;
    }

    return '';
  };

  const renderFieldError = (fieldName: keyof T) => {
    const fieldValue = formState[fieldName] as unknown as string;

    if (!fieldValue) {
      return 'Cannot be blank.';
    }

    if ((fieldName as string) === 'email') {
      return renderEmailError(fieldValue);
    }

    const fieldNameForMessage = upperFirst(lowerCase(fieldName as string));
    return renderTextFieldError(fieldNameForMessage, fieldValue);
  };

  return {
    formState,
    setFormState,
    renderFieldError,
    onChange,
  };
}
